// Libs
import React, { useEffect, useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// images
import MapImage from 'images/mapsImages/itaim-detalhes.png';

// Components
import SEO from 'components/seo';
import { device } from 'components/device';
import Layout from 'components/layout';

// Image
import AddressTimes from 'components/units/unitPages/unit/addressTimes';
// import Downloads from 'components/unitPages/details/downloads'
import FeaturedMarketing from 'components/units/unitPages/unit/detailsFeaturedMarketing';
// import PublicTransportation from 'components/unitPages/details/publicTransportation';
import SocialShare from 'components/units/unitPages/details/socialShare';
import TextSection from 'components/units/unitPages/details/TextSection';
import DetailsTitle from 'components/units/unitPages/details/DetailsTitle';

import BreadCrumb from 'components/breadCrumb';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};
  @media ${device.mobile} {
    padding: 0 20px 20px;
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};
`;

const Container = styled.div`
  display: grid;
  grid-column: 2 / -2;
  grid-template-columns: repeat(12, 70px);
  column-gap: 30px;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderFeaturedMarketing = page => (
  <FeaturedMarketing
    WithoutItemBg
    servicesList={getMarkdown(page, 'ckhfl7qe00ast0983inyeuqa9', true)}
    singleText={getMarkdown(page, 'ckhfl7qe00ast0983inyeuqa9')}
    asset={getMarkdown(page, 'ckhfl7qe00ast0983inyeuqa9', false, true)}
  />
);

const renderPage = (page, isDesktop, location) => (
  <GridContainer isDesktop={isDesktop}>
    <Part gridColumn={'2 / -2'}>
      <BreadCrumb
        details
        markdown={getMarkdown(page, 'ckhf8dv2o18fs0925iwxo185z', true)}
      />
      <DetailsTitle
        titleWidth="9rem"
        titleWidthMobile="9rem"
        littleTop
        markdown={getMarkdown(page, 'ckhexwdkg0ah70b73h02pgu9i', true)}
      />
    </Part>
    {!isDesktop && renderFeaturedMarketing(page)}
    <Container>
      <Part gridColumn={'1 / span 6'}>
        {isDesktop && renderFeaturedMarketing(page)}
        {/* About */}
        <TextSection
          isAbout
          isAboutMobile
          gridArea="ABT"
          isShowing
          doNotApplyDisplayBlock
          markdown={getMarkdown(page, 'ckhbfk4oon8gc0a305dq7v3jg', true)}
          customBlockquote
        />
        {/* Structure */}
        <TextSection
          markdown={getMarkdown(page, 'ckhbfna40n8mu0a267fhomokr', true)}
          gridArea="STRUC"
          isAboutMobile
          isShowing
        />
        {/* Others Units Mobile */}
        <TextSection
          markdown={getMarkdown(page, 'ckiqrm78w2dnp0972x8hl94kv', true)}
          isShort={true}
          gridArea="PAR"
          isAboutMobile
          isOthersUnits
        />
      </Part>
      <Part gridColumn={'8 / span 5'}>
        <AddressTimes
          markdown={getMarkdown(page, 'ckhyydelc1psy0a73yih91zih', true)}
          fragments={getMarkdown(page, 'ckhevms2g054k0a3173k05ngk')}
          location={{ lat: -23.5848177, lng: -46.674954 }}
          zoom={17}
          isDetails
          isMarginLeft
          isShowingAttention
          mapPreview={MapImage}
          isStatic
          isDesktop={isDesktop}
        />
        {/* Schedule */}
        <TextSection
          markdown={getMarkdown(page, 'ckhbfpei0n8t4093114mz89kf', true)}
          gridArea="SCH"
          isShort
          isAboutMobile
          isShowing
        />
        {/* Parking */}
        <TextSection
          markdown={getMarkdown(page, 'ckhbfqsnkn8wt0931udj5i6is', true)}
          gridArea="PAR"
          isShort
          isAboutMobile
          isShowing
        />
        {/* <PublicTransportation
          markdown={getMarkdown(page, 'ckhgi5ins0clw0b21h3dteskg', true)}
          fragments={getMarkdown(page, 'ckhgi5ins0clw0b21h3dteskg')}
          whichLine="Itaim"
        /> */}
        {/* <Downloads /> */}

        {/* Others Units */}
        <TextSection
          markdown={getMarkdown(page, 'ckiqrm78w2dnp0972x8hl94kv', true)}
          isShort={true}
          gridArea="PAR"
          isAboutMobile
          isOthersUnits
          isShowing
          isOthersUnitsMobile
        />
        {isDesktop && (
          <SocialShare location={location} title="Detalhes da Unidade Itaim" />
        )}
      </Part>
    </Container>
    {!isDesktop && (
      <SocialShare location={location} title="Detalhes da Unidade Itaim" />
    )}
  </GridContainer>
);

const ItaimDetails = ({ page, location }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'ckhfl7qe00ast0983inyeuqa9', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop, location)}
    </Layout>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckox7vm1c6f0a0b75pryp2arx" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                    url
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <ItaimDetails page={response.gcms.site.pages} location={location} />
        );
      }}
    />
  );
};
